.App {
  background-color: #312323;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
  touch-action: none;
  height: 100vh;
  width: 100vw;
}

.App-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.App-link {
  color: #61dafb;
}
